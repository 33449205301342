<template>
			<div>
        <render-content id="about"></render-content>
			</div>
</template>

<script>
  import RenderContent from "@c/ui/RenderContent"
  export default {
    name: 'About',
    components: {
      RenderContent
    },
  }
</script>
